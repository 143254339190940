<template>
  <div class="JjjcSeeWrap">
    <div class="JjjcSeeTop">
      <div class="JjjcSeeBox1">
        <div class="JjjcSeeTitle">
          <span></span>
          <b>基本信息</b>
        </div>
        <div class="JjjcSeeContent">
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">姓名</span>
            </p>
            <van-field v-model="Form.Name" readonly placeholder="请输入姓名" />
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">身份证号</span>
            </p>
            <van-field
              v-model="Form.Ider"
              readonly
              placeholder="请输入身份证号"
            />
          </div>
        </div>
      </div>
      <div class="JjjcSeeBox2">
        <div class="JjjcSeeBoxName">
          <!-- <span class="star">*</span> -->
          <p>
            <span class="star">*</span>
            <span class="JjjcSeeBoxTitle">当前体温：</span>
          </p>
          <span class="JjjcSeeBoxTitleTemperature">
            <van-field
              readonly
              clickable
              :value="Form.temperature"
              placeholder="请输入体温"
              @touchstart.native.stop="show = true"
            />
            <van-number-keyboard
              v-model="Form.temperature"
              :show="show"
              :maxlength="4"
              extra-key="."
              @blur="show = false"
              @hide="() => hide(Form.temperature)"
              @show="() => input(Form.temperature)"
            />
          </span>
        </div>
        <van-radio-group v-model="Form.Time" direction="horizontal">
          <van-radio :name="1">上午</van-radio>
          <van-radio :name="2">下午</van-radio>
        </van-radio-group>
      </div>
      <div class="JjjcSeeBox3">
        <div class="JjjcSeeHsImg">
          <span>上传温度计照片</span>
          <span>（点击上传图片，限一张）</span>
        </div>
        <van-uploader
          v-model="fileList"
          multiple
          :max-count="1"
          :after-read="ImgClick"
        />
      </div>
      <div class="JjjcSeeBox3">
        <div class="JjjcSeeHsImg">
          <span>上传核酸检测报告</span>
          <span>（请上传最近一次核酸检测报告阴性证明）</span>
        </div>
        <van-uploader
          v-model="fileListHS"
          multiple
          :max-count="1"
          :after-read="ImgClickHS"
        />
      </div>
    </div>
    <van-button type="info" class="JjjcSeeBtn" @click="JjjcBtn">
      提交
    </van-button>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import {
    updatetemperature,
    addproblemimgbypath,
    addproblemimg,
  } from '@/api/Content';
  import storage from 'store';
  import { Toast } from 'vant';
  const BASE_OSS_URL =
    process.env.NODE_ENV === 'production'
      ? window.location.origin + '/oss/'
      : 'https://yqtb.yode.cn/oss/';
  export default {
    data() {
      return {
        BASE_OSS_URL,
        Form: {
          Name: '',
          Ider: '',
          Time: '',
          temperature: '',
        },
        show: false,
        HsImg: require('@/assets/HsImg.svg'),
        dushu: '',
        fileList: [],
        WDJimg: '',
        fileListHS: [],
        HSimg: '',
        WDNumber: '',
      };
    },
    computed: {
      ...mapState('user', ['userInfo']),
    },
    mounted() {
      // this.Form.Name = this.userInfo.username;
      // this.Form.Ider = this.userInfo.idcard;
      this.Form.Name = storage.get('UserName');
      this.Form.Ider = storage.get('Idcard');
    },

    methods: {
      ImgClick(e) {
        const fd = new FormData();
        fd.append('file', e.file);
        addproblemimg(fd).then((data) => {
          this.WDJimg = data.result;
        });
      },
      ImgClickHS(e) {
        const file = new FormData();
        file.append('file', e.file);
        addproblemimg(file).then((data) => {
          this.HSimg = data.result;
        });
      },
      JjjcBtn() {
        console.log(this.WDNumber, 'this.WDNumber');
        if (this.Form.Time == '') {
          return Toast('请选择时间');
        }
        if (this.WDNumber == '') {
          return Toast('请输入今日体温');
        }
        updatetemperature({
          itype: this.Form.Time,
          nucleinpic: this.HSimg,
          temperature: this.WDNumber,
          temperaturepic: this.WDJimg,
        })
          .then((data) => {
            Toast('提交成功!');
            window.history.go(-1);
          })
          .catch((data) => {
            Toast('用户没有居家隔离,不用上传体温!');
          });
      },
      input(e) {
        this.Form.temperature = e.slice(0, e.length - 1);
      },
      hide(e) {
        this.dushu = '度';
        // this.Form.temperature = e + this.dushu;
        this.Form.temperature = e;
        this.WDNumber = e;
      },
    },
  };
</script>
<style lang="less" scoped>
  .JjjcSeeWrap {
    width: 100%;
    height: auto;
    background: #fafcfd;
  }
  .JjjcSeeBox1 {
    width: 100%;
    height: 13.5rem;
    border-radius: 6px;
    background: #fff;
    margin: 1rem 0 0.6rem 0;
  }
  .JjjcSeeTitle {
    display: flex;
    align-items: center;
    margin: 1rem 0 2rem 1rem;
    span {
      display: inline-block;
      width: 0.4rem;
      height: 0.8rem;
      border-radius: 3px;
      background: #0d4eed;
      margin: 0 0.4rem 0 0;
    }
  }
  .star {
    color: #ff0000;
  }
  .JjjcSeeContent {
    .wire {
      background: transparent;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);
      width: 90%;
      margin: 0 0 6% 5%;
      .title {
        font-family: 'PingFang SC Medium';
        font-weight: bold;
        font-size: 1rem;
        text-align: left;
        color: #1c1c1c;
      }
    }
  }
  .JjjcSeeBox2 {
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: start;
    margin: 0 0 0.6rem 0;
    background: #fff;
    padding: 1rem 0 1rem 0;
    .JjjcSeeBoxName {
      width: 12rem;
      margin: 0 0 0 7%;
      display: flex;
      flex-direction: column;
      .JjjcSeeBoxTitle {
        font-family: 'PingFang SC Medium';
        font-weight: bold;
        font-size: 1rem;
        text-align: left;
        color: #1c1c1c;
        margin: -3px 0 0.8rem 0;
      }
      .JjjcSeeBoxTitleTemperature {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 0.8rem;
        text-align: left;
        color: #575757;
        display: flex;
        align-items: center;
      }
    }
  }
  .JjjcSeeBox3 {
    width: 100%;
    // height: 3.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 0 0.6rem 0;
    .JjjcSeeHsImg {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 7%;
      justify-content: center;
      span:nth-of-type(1) {
        font-family: 'PingFang SC Medium';
        font-weight: bold;
        font-size: 1rem;
        text-align: left;
        color: #1c1c1c;
        margin: 0 0 0.5rem 0;
      }
      span:nth-of-type(2) {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 0.7rem;
        text-align: left;
        color: #a3a3a3;
      }
    }
  }
  .HsImg {
    width: 5rem;
    height: 5rem;
    margin: 0 2.7rem 0 0;
  }
  .JjjcSeeTop {
    height: 87vh;
    overflow: auto;
  }
  .JjjcSeeBtn {
    width: 96%;
    margin: 0 0 0 2%;
    border-radius: 5px;
  }
</style>
<style lang="less" scoped>
  ::v-deep {
    .JjjcSeeBoxTitleTemperature {
      // .van-field__control {
      //   text-align: right;
      // }
      .van-cell {
        width: 5rem;
        padding: 10px 0 10px 0;
      }
    }
    .van-uploader {
      margin: 0 2.7rem 0 0;
    }
    .van-cell::after {
      border: 0;
    }
  }
</style>
