import request from '@/utils/request';
import qs from 'qs';
const contentType = {
  form: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
  json: { 'Content-Type': 'application/json;charset=UTF-8' },
};
//通过临时码登录
export function areadictlist(dictid) {
  return request({
    url: `/api/epi/dict/getareadictlist/${dictid}`,
    method: 'post',
    headers: contentType.form,
  });
}
//获取省列表
export function provinces() {
  return request({
    url: '/api/epi/dict/getprovinces',
    method: 'post',
    headers: contentType.form,
  });
}
//获取市列表
export function cities(dictid) {
  return request({
    url: `/api/epi/dict/getcities/${dictid}`,
    method: 'post',
    headers: contentType.form,
  });
}
//获取区列表
export function area(dictid) {
  return request({
    url: `/api/epi/dict/getarea/${dictid}`,
    method: 'post',
    headers: contentType.form,
  });
}
//保存返德人员信息
export function savepersonneinfo(data) {
  return request({
    url: '/api/epi/personnel/savepersonneinfo',
    method: 'post',
    headers: contentType.json,
    data,
  });
}
//获取返德人员信息
export function personneinfo() {
  return request({
    url: '/api/epi/personnel/getpersonneinfo',
    method: 'post',
    headers: contentType.form,
  });
}
//按url地址增加随手拍照片
export function addproblemimgbypath(data) {
  return request({
    url: '/api/epi/temperature/addproblemimgbypath',
    method: 'post',
    headers: contentType.form,
    data: qs.stringify(data),
  });
}
//保存返德人员信息
export function updatetemperature(data) {
  return request({
    url: '/api/epi/temperature/updatetemperature',
    method: 'post',
    headers: contentType.json,
    data,
  });
}
//增加照片
export function addproblemimg(data) {
  return request({
    url: '/api/epi/temperature/addproblemimg',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}
